import request from "@utils/request";

//传输记录
export  const shareRecord = (params)=>{
    return request({
        url:'index/member/shareRecord',
        method:'post',
        params,
    })

}

//修改链接时间
export const  editExpiryTime = (params)=>{
    return request({
        url:'index/member/editExpiryTime?',
        method:'post',
        params,
    })
}

//分享链从新上传 文件/文件夹
export const reUploadShareByFiles =(data)=>{
    return request({
        url:'index/member/reUploadShareByFiles',
        method:'post',
        data,
    })
}
//分享从新上传 云盘
export const reUploadShareByDisk = (data)=>{
    return request({
        url:'index/member/reUploadShareByDisk',
        method:'post',
        data
    })
}
//销毁任务
export const deleteShare = (data)=>{
    return request({
        url:'index/member/deleteShare',
        method:'post',
        data
    })
}
//下载分享链接内的全部文件
export const myShareDownload = (params,onUploadProgress)=>{
    return request({
        url:'index/member/myShareDownload',
        method:'post',
        params,
    },onUploadProgress)
}
//获取链接设置参数
export const getShareInfo = (params)=>{
    return request({
        url:'index/member/getShareInfo',
        method:'post',
        params
    })
}

//获取取件记录(消费记录 传输连接 取件记录)
export const  getExpensesRecordList = (params)=>{
    return request({
        url:'index/shop/getExpensesRecordList',
        method:'post',
        params
    })
}
//提交取件记录
export const editShare = (params)=>{
    return request({
        url:'/index/member/editShare',
        method:'post',
        params
    })
}
