<template>
    <div class="kc-header-box">
      <div class="kc-header">
      <div class="header-left" @click="$router.push('/home')" @mouseenter.stop="show=true" @mouseleave="show = false" >
            <img :src="webInfo.logo_img" class="logo"/>
            <span>{{webInfo.web_name}}</span>
            <img src="@assets/img/index/icon-drop.png" :class="show?'drop active-drop':'drop'"/>

          <el-collapse-transition>
            <div class="other-card" v-show="show"   @mouseleave="show = false">
              <ul>
                <li v-for="(item,index) in homeInfo.logo_link_list">
                  <div @click.stop="Jump(item)" >{{item.title}}</div>
                </li>
              </ul>
            </div>
          </el-collapse-transition>
        </div>
        <div v-if="!token" class="header-right">
          <router-link  to="/help">
            <span class="font">帮助</span>
          </router-link>
          <router-link to="/home" style="border-right: none;"   >
        <span class="font" >
          注册/登录
          <!--   小红点   -->
          <span class="badge"></span>
        </span>
          </router-link>
        </div>
      <div v-else class="header-right">
        <div class="handel-router">
            <router-link to="/shop">升级会员</router-link>
            <router-link to="/home">文件传输</router-link>
            <router-link to="/myFiles">我的云盘</router-link>
            <router-link to="/transferRecord">传输记录</router-link>
        </div>
        <div class="other">
            <img src="@assets/img/index/icon-help.png" @click="$router.push('/help')"/>
            <span v-show="userInfo.not_read_num >0" class="tips"></span>

          <img src="@assets/img/index/icon-notice.png" @click="$router.push('/messageNotice')"/>
          <img :src="userInfo.avatar"  @mouseout="show1 = false" @mousemove="show1 = true"    @click="show1 = !show1" class="avatar"/>
        </div>
      </div>
<!--        @mouseout.prevent="show1 = false"  @mousemove.prevent="show1 = true"-->
        <el-collapse-transition>
        <div class="header-popuBox" v-show="show1"  @mouseout.prevent="show1 = false"  @mousemove.prevent="show1 = true" @click="show1 = !show1"  >
          <div class="kc-UserInfoBox" @click="$router.push('/setting')" >
              <div class="user" >
                <div class="kc-level"  >
                  <img  :class="userInfo.vip_status == 1?'kc-avatar':'kc-avatar1'" :src="userInfo.avatar" alt="">
                  <img v-if="userInfo.vip_status == 1" :src="getShopUserLevelImg(userInfo,time)"  class="kc-vip"  alt="">
                </div>
                <div class="nameBox" >
                  <div class="name">
                    <span>{{userInfo.username}}</span>
                    <img v-if="userInfo.vip_status == 1"  @click="toShop(1)" class="vipType" :src="userInfo.vip_level | getVipType" alt="">
                  </div>
                  <span @click.stop="$router.push('/shop')" :style="{color:userInfo.vip_status == 0 ?'#0080FF':'#333333',fontSize:12 + 'px'}" class="member">{{userInfo.vip_status == 0?'开通点亮VIP': userInfo.vip_level_show +  '至:' + userInfo.vip_expiry_time}}</span>
                </div>
              </div>
            </div>
          <div class="btnBox">
            <ul>
              <router-link to="/setting">
                <li>
                <img src="@assets/img/index/icon-title1.png" alt="">
                账号设置
              </li>
              </router-link>
              <router-link to="/Shop">
                <li>
                <img src="@assets/img/index/icon-title2.png" alt="">
                 迅鸟商城
              </li>
              </router-link>
              <router-link to="/MyWallet">
                <li>
                  <img src="@assets/img/index/icon-title5.png" alt="">
                  我的钱包
                </li>
              </router-link>
              <router-link to="/messageNotice">
                <li>
                  <img src="@assets/img/index/icon-title6.png" alt="">
                  消息通知
                  <span v-show="userInfo.not_read_num >0" class="tips"></span>
                </li>
              </router-link>
              <router-link to="/uploadBackground">
                <li>
                  <img src="@assets/img/index/icon-title7.png" alt="">
                  传输背景
                </li>
              </router-link>
              <router-link to="/freeExpansion">
                <li>
                  <img src="@assets/img/index/icon-title3.png" alt="">
                  免费扩容
                </li>
              </router-link>
              <router-link to="/coupon">
                <li>
                  <img src="@assets/img/index/icon-title4.png" alt="">
                  优惠券
                </li>
              </router-link>
              <div @click="gologin" to="">
                <li>
                  <img src="@assets/img/index/icon-title-out.png" alt="">
                  登出
                </li>
              </div>

            </ul>
          </div>
        </div>
      </el-collapse-transition>
    </div>

      <!--    下载弹窗  -->
      <overallPopup  />
    </div>
</template>

<script>
import {getUserInfo} from "@assets/api/user/user";
import {mapMutations, mapState} from "vuex";
import {getHomeInfo, getPanList, getWebInfo, inquireTask} from "@assets/api/home/home";
import {baseUrl} from "../../config";
import {compressPackage, diskDownload, uploadFile} from "@assets/api/myFiles";
import axios from "axios";
import {formatSize} from "@utils/function";
import overallPopup from "@components/overallPopup";

export default {
  name:'',
  components: {
    overallPopup
  },

  data () {
    return {
      show:false, //logo旁下拉菜单显示
      show1:false,//头像弹窗
      // userInfo:{},
      token:localStorage.getItem('token') || this.$store.state.token,
      homeInfo:{},
      webInfo:{},
      uploadFileList:[],
      uploadBefore:{},//上传前参数
      shardSize:'',//切片单片大小
      folder_path:'',//云盘上传路径
      fileId:0,//单选文件id
      // flag:true,//上传开关
      cancel:'',//中断请求
      uploadSize:0,//上传字节
      fileName:'',//每次上传的文件名
      uploadfileId:'',//文件id
      fileTotalSize:'',//每次上传文件的字节
      percentage:'',//上传进度
      showUpload:false,//上传弹窗
      showUploadAction:true,//上传弹窗动画
      //下载
      showZip:false,//下载弹窗收起
      showZipList:'',//下载弹窗
      vipType:'',
      time:'',//时间
      //下载弹窗数据数组
      packZipList:[],
      showTooltips:false,
      f1:false,
      f2:false,
      f3:false,
      downLoadTotalSize:0,//下载提示弹窗字节
      //下载文件数组
      downLoadList:[],
      freeDown:'',//免费下载
    };
  },

  computed: {
    ...mapState(
        {
          userInfo:'userInfo',
          uploadFile:'uploadFile',
          flag:'flag',
          uploadResponse:'uploadResponse',
          downLoadInfo:'downLoadInfo',
          packZipInfo:'packZipInfo',
          uploadRandom:'uploadRandom'
        },
    ),
    //上传成功的文件
    successFile(){
      return this.uploadFileList.filter(item=>{
        return item.state == 'success' && item.percentage == 100
      }).length
    },
  },
  filters:{
    getVipType(val){
      if(val == 1){
        return require('@assets/img/index/icon-vip5.gif')
      }
      if(val == 2){
        return require('@assets/img/index/icon-vip5.gif')
      }
      if(val >= 3){
        return require('@assets/img/index/icon-vip5.gif')
      }
    },
  },
  watch:{
    showUpload(newVal){
      if(newVal == false){
        this.$store.state.uploadFile.uploadState = newVal
      }
    },
    uploadFile(newVal){
      this.uploadFileList = newVal.fileList
      this.uploadBefore = newVal.uploadBefore
      this.folder_path = newVal.folder_path
      this.showUpload = newVal.uploadState
    },

    uploadLoaded(newVal,oldVal){
      let speed = newVal - oldVal
      let upload_speed = Math.round(((this.uploadSize + newVal) * 100) / this.fileTotalSize)
      this.percentage = upload_speed >= 100 ? 100:upload_speed;
      let t1 = new Date(); // 已上传文件大小时间
      let s = Math.round(
          speed / 1024
      );
      if (s > 1024) {
        this.speed = (s / 1024).toFixed(2) + "M/s";
      } else {
        this.speed = s < 0 ?1  + "kb/s":s+"kb/s";
      }
      for(let item in this.uploadFileList){
        if(this.uploadFileList[item].name == this.fileName && this.uploadFileList[item].uuid == this.uploadfileId){
          this.$set(this.uploadFileList[item],'percentage',this.percentage)
          this.$set(this.uploadFileList[item],'speed',this.speed)
        }
      }
    },
  },
  created(){
    this.getUser()
    this.getHomeData()
    this.getBasicData()
    this.getNowDate()
    this.token =  localStorage.getItem('token') || this.$store.state.token
    if(this.uploadFile){
      this.uploadFileList = this.uploadFile.fileList ?this.uploadFile.fileList:[]
      this.uploadBefore = this.uploadFile.uploadBefore
      this.folder_path = this.uploadFile.folder_path
      this.showUpload = this.uploadFile.uploadState
    }
  },

  mounted() {
  },

  methods: {
    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    //获取网站基本信息 例:账号密码 容器地址
    getBasicData(){
      getWebInfo().then(res=>{
        this.webInfo = res.data
        // this.$store.commit('getWebInfo',this.webInfo)
        // window.SeoUpdate(`${this.webInfo.title}`,`${this.webInfo.keywords}`,`${this.webInfo.description}`);
        window.SeoUpdate(`${this.webInfo.title}`, `${this.webInfo.keywords}`, `${this.webInfo.description}`);

        (function (item) {
          var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          // link.rel = 'shortcut icon';
          link.href = item.ico_img;
          document.getElementsByTagName('head')[0].appendChild(link);
        })(this.webInfo)
      })
    },
    //用户信息
    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          let userInfo = res.data
          // let time = userInfo.vip_expiry_time.split(' ')
          // userInfo.vip_expiry_time = time[0]
          this.$store.commit('getUserInfo',userInfo)
        }else{
          this.token = ''
          this.$store.state.token = ''
          localStorage.removeItem('token')
        }
      })
    },
    speedProgress(percentage){
      return percentage +'%已使用'
    },
    //跳转
    gologin(){
      localStorage.setItem('token','')
      this.$store.state.token = ''
      this.$router.replace('/home')
      location.reload()
    },
    //首页信息
    getHomeData(){
      getHomeInfo().then(res=>{
        this.homeInfo = res.data
      })
    },
    //跳转
    Jump(item){
      if(item.jump_type == 1){
        window.open(item.url,"_blank");
      }else{
        this.$router.push({
          path:'/advice',
          name:'Advice',
          params:{
            id:item.news_id
          }
        })
      }
    },
    toShop(index){
      this.showTooltips = false
      this.$router.push({
        path:'/shop',
        name:'Shop',
        params:{
          index:index
        }
      })
    }

  },

}

</script>
<style lang='less' scoped>
.kc-header-box{
  .kc-header{
    z-index: 99;
    position: sticky;
    top: 0;
    padding: 0 20px;
    height: 70px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left{
      display: flex;
      align-items: center;
      cursor: pointer;
      .logo{
        width: 45px;
      }
      span{
        font-size: 16px;
        //font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin: 0 5px;
      }
      .drop{
        cursor: pointer;
        width: 14px;
      }
      .other-card {
        padding: 10px;
        position: absolute;
        top: 0;
        left: 28px;
        margin-top: 60px;
        // width: 105px;
        // height: 130px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        backdrop-filter: blur(4px);
        div {
          display: block;
          text-align: center;
          //width: 80px;

          border-radius: 8px;
          font-size: 14px;
          //font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #333333;
          line-height: 35px;
        }
        div:hover {
          background: #efefef;
        }
      }
    }
    .header-right {
      z-index: 9;
      position: absolute;
      //top: 30px;
      right: 0;
      display: flex;
      align-items: center;
      //height: 60px;
      background: #ffffff;
      border-radius: 4px;
      //backdrop-filter: blur(4px);
      .handel-router{
        font-size: 16px;
        //font-family: PingFang-SC-Regular;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        a{
          color: #333333;
        }
        a:hover{
          color:#12bfd7;
        }
      }
      .other{
        width: 146px;
        border-left: 1px solid rgba(0,0,0,0.2);
        height: 10px;
        display: flex;
        align-items: center;
        .tips{
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: red;
          right: 70px;
          top: 2px;
        }
        img{
          width: 16px;
          height: 16px;
          margin-left: 20px;
          cursor: pointer;
        }
        .avatar{
          width: 20px;
          height: 20px;
          margin-left: 30px;
          border-radius: 50%;
        }
      }
      a{
        padding: 14px 15px;
        .font{
          font-size: 18px;
          //font-family: PingFang-SC-Regular;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          box-sizing: border-box;
          position: relative;
          .badge{
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            right: -3px;
            background: #FF2E2E ;
            top: -3px;
          }

        }


      }

    }
    .header-popuBox{
      position: absolute;
      width: auto;
      height: auto;
      min-width: 283px;
      background: #FFFFFF;
      border-radius: 5px;
      right: 10px;
      top: 75px;
      box-sizing: border-box;
      cursor:default;
      padding-bottom: 25px;
      .kc-UserInfoBox{
        width: 100%;
        .user{
          width: 235px;
          border-bottom: 1px solid #F4F6F8;
          box-sizing: border-box;
          display: flex;
          align-items: flex-start;
          padding: 24px 0;
          margin: 0 auto;
          cursor: pointer;
          .kc-level{
            position: relative;
            width: 54px;
            display: flex;
            justify-content: center;
            height: 54px;
            .kc-avatar{
              width: 45px;
              height: 45px;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
            .kc-avatar1{
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }
            .kc-vip{
              position: relative;
              width: 54px ;
              height: 54px ;
              z-index: 2;
              object-fit: contain ;
            }
          }
          .memberPopup{
            width: 254px;
            height: 130px;
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            position: absolute;
            bottom: 75px;
            z-index: 3;
            .top{
              width: 100%;
              height: 71px;
              border-bottom: 1px #F7F7F7  dashed;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              span{
                width: 100%;
                font-size: 14px;
                //font-family: AliMedium;
                font-weight: 500;
                color: #333333;
                text-align: center;
              }
            }
            .bottom{
              width: 100%;
              height: calc(100% - 71px);
              display: flex;
              align-items: center;
              justify-content: center;
              span{
                cursor: pointer;
                font-size: 14px;
                //font-family: AliMedium;
                font-weight: 500;
                color: #FF5959;
              }
            }
          }
          .cone {
            width: 0;
            height: 0;
            border-bottom: 10px solid transparent;
            border-top: 10px solid rgba(255, 255, 255, 1);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent ;
            filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
            position: absolute;
            bottom: -20px;
            left: 50%;
          }
          .nameBox{
            //width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
            height: 50px;
            margin-left: 10px;
            .name{
              width: 100%;
              font-size: 16px;
              //font-family: AliMedium;
              font-weight: 500;
              color: #333333;
              display: flex;
              align-items: center;
              white-space: nowrap;
              .vipType{
                width: 36px;
                height: 18px;
                margin-left: 4px;
              }
            }
            .member{
              width: 100%;
              font-size: 14px;
              //font-family: PingFang-SC-Regular;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              white-space: nowrap;
            }
          }
        }


      }
      .btnBox{
        //padding: 15px 18px 0 18px;
        ul{
          a{
            width: 100%;
            display: block;
          }
          li{
            width: 100%;
            height: 100%;
            font-size: 14px;
            padding: 12px 0;
            //font-family: PingFang-SC-Regular;
            font-weight: 400;
            color: #333333;
            line-height: 17px;
            display: flex;
            align-items: center;
            cursor:pointer;
            position: relative;
            img{
              width: 22px;
              height: 22px;
              margin-right: 15px;
              margin-left: 25px;
            }
            .tips{
              //position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: red;
              margin-bottom: 15px;
              //left: 128px;
              //top: 8px;
              //border: 1px solid black;
            }
          }
          li:hover{
            background: #F4F6F8;
            opacity: 0.59;
          }
        }
      }
    }
  }
  .handlerPopup{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99999;
    //上传弹窗
    .upload-dialog{
      //position: fixed;
      right: 20px;
      bottom: 20px;
      width: 562px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1000);
      border-radius: 6px 13px 13px 13px;
      margin-bottom: 15px;
      .upload-dialog__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background: #3B4E99;
        border-radius: 6px 13px 0px 0px;
        padding: 0 20px;
        .upload-dialog__header-title{
          font-size: 16px;
          //font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
        .upload-dialog__header-right{
          display: flex;
          align-items: center;
          img{
            transition: all .3s;
            cursor: pointer;
            width: 16px;
          }
        }
      }
      .uploader-list{
        max-height: 350px;
        overflow: auto;
        .file-item{
          display: flex;
          align-items: center;
          height: 50px;
          padding: 0 20px 0 30px;
          border-bottom: 1px solid #F8F8F8;
          .file-name{
            display: flex;
            align-items: center;
            img{
              width: 16px;
              margin-right: 5px;
            }
            p{
              width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              //font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
          .file-size{
            text-align: center;
            width: 100px;
            font-size: 14px;
            //font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
          }
          .file-operate{
            flex: 1;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .speed{
              position: absolute;
              left: 0;
              font-size: 12px;
              //font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
            }
            .progress{
              position: absolute;
              left: 65px;
              font-size: 14px;
              //font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
            img{
              cursor: pointer;
              margin-left: 15px;
              width: 16px;
            }
            .loading{
              animation: loading 2s linear infinite;
            }
          }

        }
      }
      .uploader-list::-webkit-scrollbar {
        width: 6px;
      }
      .uploader-list::-webkit-scrollbar-thumb {
        background: #DEDDDD;
        border-radius: 3px;
      }
      .zip-list::-webkit-scrollbar {
        width: 6px;
      }
      .zip-list::-webkit-scrollbar-thumb {
        background: #DEDDDD;
        border-radius: 3px;
      }
      .zip-list{
        max-height: 324px;
        padding: 10px 0;
        overflow: auto;
        .zip-item{
          display: flex;
          align-items: center;
          padding: 10px 16px 10px 10px;
          .zip-item-content{
            flex: 1;
            display: flex;
            align-items: center;
            img{
              width: 56px;
              height: 56px;
              margin-right: 5px;
              flex-shrink: 0;
            }
            .zip-item-info{
              flex: 1;
              h6{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 10px;
                font-size: 16px;
                //font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
              }
              p{
                font-size: 13px;
                //font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
              }
            }
          }
          .zip-operate{
            display: flex;
            align-items: center;
            span{
              cursor: pointer;
              font-size: 14px;
              //font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #3B4E99;
              line-height: 20px;
            }
            img{
              width: 16px;
            }
          }
        }
      }
    }

  }
  //下载或预览图片
  .downloadPupBox{
    box-sizing: border-box;
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    .downloadPup{
      width: 374px;
      background: rgba(255,255,255,0.8);
      box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
      padding: 26px 30px 20px;
      border-radius: 8px;
      backdrop-filter: blur(4px);
      margin: 0 auto;
      .del{
        position: absolute;
        width: 14px;
        height: 14px;
        top: 10px;
        right: 10px;
      }
      .title{
        font-size: 18px;
        //font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #07A8F0;
        width: 100%;
        text-align: center;
      }
      .fontBox{

        font-size: 16px;
        //font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        margin-top: 20px;
      }
      .btnBox{
        width: 100%;
        display: flex;
        margin-top: 25px;
        cursor: pointer;
        .btn1{
          width:166px;
          height: 40px;
          background: linear-gradient(143deg, #22DEB5 0%, #06A7F0 100%);
          border-radius: 20px;
          font-size: 18px;
          //font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
          margin-right: 20px;
        }

        .btn2{
          width: 90px;
          height: 40px;
          background: rgba(255,255,255,0.6);
          border-radius: 20px;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(35, 223, 180, 1), rgba(6, 166, 242, 1)) 1 -1;
          font-size: 18px;
          //font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #0AAFE7;
          line-height: 40px;
          text-align: center;
          //clip-path:inset(20px 20px round 20px)
        }
        .btn3{
          width: 339px;
          height: 40px;
          background: linear-gradient(143deg, #22DEB5 0%, #06A7F0 100%);
          border-radius: 2px;
          font-size: 18px;
          //font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
          img{
            margin-left: 4px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }

  }

}
@keyframes loading{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/deep/.el-progress-bar__inner{
  text-align: center;
}
</style>